/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://abtaxcc3wzczheuoxlq6bkteja.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ordsdtxvwfgnbpszkvrwddd7ni",
    "aws_cognito_identity_pool_id": "us-east-1:9ebedae1-3e73-4ed6-b31f-02bf6132b429",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_szrCgucEB",
    "aws_user_pools_web_client_id": "3pnite88g9ak8sep7go852s8cb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "johnyutv33011-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "map9a53fba7-staging": {
                        "style": "RasterEsriImagery"
                    }
                },
                "default": "map9a53fba7-staging"
            }
        }
    }
};


export default awsmobile;
